import React from 'react';
import styles from './LoginScreen.module.css';
import Button from "../../components/Button/Button";
import Textfield from "../../components/Textfield/Textfield";

export type LoginPageType = 'login' | 'qr-code' | 'token';

export interface LoginScreenProps {
    username: string;
    setUsername: (username: string) => void;
    token: string;
    setToken: (token: string) => void;
    password: string;
    setPassword: (password: string) => void;
    onLogin: () => Promise<void>;
    check2FaStatus: () => Promise<void>;
    loading: boolean;
    showError: boolean;
    qrCode: string;
    page: LoginPageType;
    setPage: (page: LoginPageType) => void;
}

const LoginScreen = ({
                         username,
                         setUsername,
                         token, setToken,
                         password,
                         setPassword,
                         onLogin,
                         check2FaStatus,
                         loading,
                         showError,
                         page,
                         setPage,
                         qrCode,
                     }: LoginScreenProps) => {
    const buttonDisabled = !username || !password;

    return (
        <div className={styles.wrapper}>
            <div className={styles.modal}>
                <div className={styles.logo}/>

                {showError && (
                    <div className={styles.error}>
                        Wrong combination of username and password.
                    </div>
                )}

                {page === 'qr-code' &&
                    <div>
                        <div className={styles.qrCodeWrapper}>
                            <div style={{marginBottom: 10}}>
                                This portal uses 2-factor authentication to protect your data. Therefore, scan the QR code below with the Authenticator app on your phone and click 'Continue'. Then enter the verification code generated by your Authenticator app in the next step.
                            </div>

                            <img src={qrCode} alt=""/>
                        </div>

                        <div className={styles.buttonWrapper}>
                            <Button
                                label="Continue"
                                onClick={() => setPage('token')}
                                loading={loading}
                                disabled={buttonDisabled}
                            />
                        </div>
                    </div>
                }

                {page === 'token' &&
                    <>
                        <div className={styles.usernameWrapper}>
                            <div style={{marginBottom: 10}}>Enter the token from the Authenticator app on your phone.</div>
                            <Textfield
                                placeholder="Verification code"
                                value={token}
                                setValue={setToken}
                                autoFocus
                            />
                        </div>

                        <div className={styles.buttonWrapper}>
                            <Button
                                label="Log in"
                                onClick={onLogin}
                                loading={loading}
                                disabled={buttonDisabled}
                            />
                        </div>
                    </>
                }
                {page === 'login' &&
                    <form onSubmit={e => e.preventDefault()}>
                        <div className={styles.usernameWrapper}>
                            <Textfield
                                placeholder="User name"
                                value={username}
                                setValue={setUsername}
                                autoFocus
                            />
                        </div>

                        <div className={styles.passwordWrapper}>
                            <Textfield
                                placeholder="Password"
                                type="password"
                                value={password}
                                setValue={setPassword}
                            />
                        </div>

                        <div className={styles.buttonWrapper}>
                            <Button
                                label="Continue"
                                onClick={check2FaStatus}
                                loading={loading}
                                disabled={buttonDisabled}
                            />
                        </div>
                    </form>
                }
            </div>
        </div>
    );
};

export default LoginScreen;
