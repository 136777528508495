import React, {useContext, useState} from 'react';
import LoginScreen, {LoginPageType} from "./LoginScreen";
import {AppContext, validate2fa} from "../../services";

const LoginScreenWrapper = () => {
    const {signIn} = useContext(AppContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [page, setPage] = useState<LoginPageType>('login');
    const [qrCode, setQrCode] = useState<string>('');

    const handleLogin = async (checkToken = true) => {
        if (loading || !username || !password) {
            return;
        }

        if(checkToken && !token) {
            return;
        }

        setLoading(true);
        try {
            return await signIn(username, password, token);
        } catch (error) {
            setLoading(false);
            setShowError(true);
        }
    };

    const check2FaStatus = async () => {
        try {
            let validation = await validate2fa(username, password);
            setShowError(false);

            if (!validation.enabled) {
                return await handleLogin(false);
            }

            if (!validation.configured) {
                setPage('qr-code');
                setQrCode(validation.image);
            } else {
                setPage('token');
            }
        } catch (error) {
            setLoading(false);
            setShowError(true);
        }
    };

    return (
        <>
            <LoginScreen
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                token={token}
                setToken={setToken}
                onLogin={handleLogin}
                check2FaStatus={check2FaStatus}
                loading={loading}
                showError={showError}
                qrCode={qrCode}
                page={page}
                setPage={(page) => {
                    setShowError(false);
                    setPage(page);
                }}
            />

        </>
    );
};

export default LoginScreenWrapper;
