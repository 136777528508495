import React from 'react';
import {H1} from "../../components/titles/titles";
import styles from "./AccountScreen.module.css";
import FieldsPane, {FieldsCol} from "../../components/FieldsPane/FieldsPane";
import Field from "../../components/Field/Field";
import ActionTileBar from "../../components/ActionTileBar/ActionTileBar";
import LoadingButton from "../../components/Button/LoadingButton";
import ActionTile from "../../components/ActionTileBar/ActionTile";

type Props = {
    name: string;
    email: string;
    onSignOut: () => Promise<void>;
    onChangePassword: () => void;
    onSwitchPortal: () => void;
    onSetup2Fa: () => void;
};

const AccountScreen = ({
                           name,
                           email,
                           onSignOut,
                           onChangePassword,
                           onSwitchPortal,
                           onSetup2Fa,
                       }: Props) => {
    return (
        <div>
            <div className={styles.titleWrapper}>
                <H1>Account info</H1>
            </div>

            <div className={styles.paneWrapper}>
                <FieldsPane>
                    <FieldsCol>
                        <Field label="Name" children={name}/>
                        <Field label="Email address" children={email}/>
                    </FieldsCol>
                </FieldsPane>
            </div>

            <div className={styles.titleWrapper}>
                <H1>Actions</H1>
            </div>
            <div className={styles.actionTilesWrapper}>
                <ActionTileBar>
                    <ActionTile
                        onClick={onChangePassword}
                        title="Choose new password"
                        icon="iconPassword"
                    />
                    <ActionTile
                        onClick={onSwitchPortal}
                        title="Switch to other portal"
                        icon="iconSwitchPortal"
                    />

                    <ActionTile
                        onClick={onSetup2Fa}
                        title="Setup 2FA"
                        icon="iconSecurity"
                    />
                </ActionTileBar>
            </div>

            <LoadingButton
                label="Log out"
                backgroundColor="red"
                onClick={onSignOut}
            />
        </div>
    );
};

export default AccountScreen;
