import innerStyles from '../Modal/ModalUploadSmsFileContainer.module.css';
import Checkbox from '../Checkbox/Checkbox';
import React, {useContext, useState} from 'react';
import Button from '../Button/Button';
import {AppContext, retrieveNewQrCode, SidebarContext, twoFactorSettingsUpdate} from '../../services';
import Textfield from '../Textfield/Textfield';
import SidebarForm from '../SidebarForm/SidebarForm';

const SidebarSetup2Fa = () => {
    const {twoFaEnabled, setTwoFaEnabled} = useContext(AppContext);
    const {hideSidebar} = useContext(SidebarContext);

    const [enabled, setEnabled] = useState(twoFaEnabled);
    const [loading, setLoading] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [token, setToken] = useState<string>('');

    const retrieveQr = async () => {
        setLoading(true);
        let qrCode = await retrieveNewQrCode();
        setLoading(false);
        setQrCode(qrCode.image);
    };

    const saveTwoFactorSettings = async () => {
        if (!isValidForm()) {
            return Promise.reject('Please enter a token from your authenticator app');
        }

        setLoading(true);
        try {
            await twoFactorSettingsUpdate(enabled, token);
            setTwoFaEnabled(enabled);
            hideSidebar();
        } catch (error) {
            if (error && error.message) {
                switch (error.message) {
                    case 'invalid-token':
                        return Promise.reject('Please enter a valid token');
                }
            }
            return Promise.reject(typeof error == 'string' ? error : 'An error occurred. Refresh this page or try again later');
        } finally {
            setLoading(false);
        }
    };

    const isValidForm = (): boolean => {
        return (enabled && token !== '') || !enabled;
    };

    return (
        <div>
            <SidebarForm title="2FA Settings" buttonLabel="Update 2FA" onSubmit={saveTwoFactorSettings}>

                <div style={{marginBottom: 10}}>
                    Protect your data and increase security by enabling 2 Factor Authentication on your POM portal
                    account now. Once enabled, you'll be required to complete an additional verification step by
                    entering a verification code generated on an Authenticator app on your phone.
                </div>

                <div className={innerStyles.notificationWrapper}>
                    <div className={innerStyles.notificationCheckboxWrapper}
                         onClick={() => setEnabled(!enabled)}>
                        <Checkbox isChecked={enabled}/>
                    </div>
                    <div className={innerStyles.notificationTextWrapper}>Enable 2FA</div>
                </div>


                {enabled &&
                    <>
                        <div style={{marginTop: 10}}>
                            <div>
                                <p>Step1: Install an Authenticator app on your phone and open it.</p>
                                <p>Step2: Generate a verification code and enter it below:</p>
                            </div>
                            <div>
                                <Textfield setValue={setToken}
                                           value={token}
                                           type="number"
                                           placeholder="Token"></Textfield>
                            </div>
                        </div>

                        <p>
                            If you did not use 2FA on the POM portal before, or if you wish to use a new Authenticator app, continue by generating a new QR code via the link below and scan it with the Authenticator app first. Then enter the token above.
                        </p>

                        {!qrCode &&
                            <Button
                                label="Generate QR code"
                                onClick={() => retrieveQr()}
                                backgroundColor="transparent"
                                disabled={loading}
                            />
                        }

                        {qrCode &&
                            <div className={innerStyles.qrCodeWrapper}>
                                <img src={qrCode} alt=""/>
                            </div>}


                    </>
                }
            </SidebarForm>
        </div>
    );
};

export default SidebarSetup2Fa;
