import React, {Component} from 'react';
import {AppContext, checkSession, fetchAllowedValues, login, logout} from "./services";
import AppNavigation from "./navigation/AppNavigation";
import {AlertProvider} from "./components/Alert/Alert";
import SizeCheck from "./components/SizeCheck/SizeCheck";

type Props = {};

type State = {
    bootstrapped: boolean;
    loggedIn: boolean;
    twoFaEnabled: boolean;
    userId: number;
    name: string;
    email: string;
    companies: Company[];
    currentCompanyId: number | null;
    allowedValues: AllowedValues;
};

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            bootstrapped: false,
            ...this.getEmptyState(),
        };

        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
        this.getStateFromServerResponse = this.getStateFromServerResponse.bind(this);
        this.handleSelectCompany = this.handleSelectCompany.bind(this);
        this.handleLoadAllowedValues = this.handleLoadAllowedValues.bind(this);
        this.setTwoFaEnabled = this.setTwoFaEnabled.bind(this);
    }

    getEmptyState() {
        return {
            loggedIn: false,
            userId: 0,
            twoFaEnabled: false,
            name: '',
            email: '',
            companies: [],
            currentCompanyId: null,
            allowedValues: {
                transactions: {},
                documents: {},
                exports: {},
                smsFiles: {},
                paylinksFiles: {},
            },
        };
    }

    async componentDidMount() {
        try {
            const data = await checkSession();
            const state = this.getStateFromServerResponse(data);

            this.setState({
                bootstrapped: true,
                ...state,
            });

            if (state.currentCompanyId) {
                this.handleLoadAllowedValues(state.currentCompanyId);
            }
        } catch (error) {
            if (error === 403) {
                //This user was logged out from the server, remove all storage
                localStorage.clear();
            }
            this.setState({
                bootstrapped: true,
            });
        }
    }

    async handleSignIn(username: string, password: string, token: string) {
        const data = await login(username, password, token);
        const state = this.getStateFromServerResponse(data);

        this.setState({
            ...state,
        });

        if (state.currentCompanyId) {
            this.handleLoadAllowedValues(state.currentCompanyId);
        }
    }

    getStateFromServerResponse(data: LoginResponse | StatusResponse) {
        let currentCompanyId = null;
        if (data.extraSettings.companies.length === 1) {
            currentCompanyId = data.extraSettings.companies[0].id;
        } else {
            const storedCurrentCompanyId = parseInt(String(localStorage.getItem('currentCompanyId')));
            if (!isNaN(storedCurrentCompanyId)) {
                if (data.extraSettings.companies.map(company => company.id).includes(storedCurrentCompanyId)) {
                    currentCompanyId = storedCurrentCompanyId;
                }
            }
        }

        return {
            loggedIn: true,
            userId: data.userId,
            twoFaEnabled: data.extraSettings.twoFaEnabled,
            name: data.extraSettings.name,
            email: data.extraSettings.email,
            companies: data.extraSettings.companies,
            currentCompanyId: currentCompanyId,
        };
    }

    async handleSignOut() {
        await logout(this.state.userId);
        this.setState(this.getEmptyState());
        localStorage.clear();
    }

    handleSelectCompany(companyId: number | null) {
        localStorage.setItem('currentCompanyId', String(companyId));
        this.setState({
            currentCompanyId: companyId,
        });

        this.handleLoadAllowedValues(companyId);
    }
    setTwoFaEnabled(twoFaEnabled: boolean): void {
        this.setState({
            twoFaEnabled: twoFaEnabled
        })
    }
    async handleLoadAllowedValues(companyId: number | null) {
        if (companyId) {
            const allowedValuesTransactions = await fetchAllowedValues('transactions', companyId);
            const allowedValuesSmsFiles = await fetchAllowedValues('smsFiles', companyId);
            const allowedValuesPaylinksFiles = await fetchAllowedValues('paylinksFiles', companyId);

            this.setState(prevState => ({
                allowedValues: {
                    ...prevState.allowedValues,
                    transactions: allowedValuesTransactions,
                    smsFiles: allowedValuesSmsFiles,
                    paylinksFiles: allowedValuesPaylinksFiles,
                },
            }));
        } else {
            const emptyState = this.getEmptyState();
            this.setState({
                allowedValues: emptyState.allowedValues,
            });
        }
    }

    render() {
        if (!this.state.bootstrapped) {
            //Show loader here if necessary
            return null;
        }

        const appContext: AppContextType = {
            loggedIn: this.state.loggedIn,
            userId: this.state.userId,
            twoFaEnabled: this.state.twoFaEnabled,
            name: this.state.name,
            email: this.state.email,
            companies: this.state.companies,
            currentCompanyId: this.state.currentCompanyId,
            allowedValues: this.state.allowedValues,

            signIn: this.handleSignIn,
            signOut: this.handleSignOut,
            selectCompany: this.handleSelectCompany,
            setTwoFaEnabled: this.setTwoFaEnabled,
        };

        return (
            <SizeCheck>
                <AppContext.Provider value={appContext}>
                    <AlertProvider>
                        <AppNavigation/>
                    </AlertProvider>
                </AppContext.Provider>
            </SizeCheck>
        );
    }
}

export default App;
