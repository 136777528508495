import React from 'react';
import * as Sentry from "@sentry/react";
import queryString from "query-string";

export const AppContext = React.createContext<AppContextType>({
    loggedIn: false,
    userId: 0,
    twoFaEnabled: false,
    name: '',
    email: '',
    companies: [],
    currentCompanyId: null,

    signIn: () => null,
    signOut: () => null,
    selectCompany: () => null,
    setTwoFaEnabled: () => null,
    allowedValues: {
        transactions: {},
        documents: {},
        exports: {},
        smsFiles: {},
        paylinksFiles: {},
    },
});

export const QueryContext = React.createContext<string>('');

export const SidebarContext = React.createContext<SidebarContextType>({
    showSidebar: () => null,
    hideSidebar: () => null,
});

export const ModalContext = React.createContext<ModalContextType>({
    showModal: () => null,
    hideModal: () => null,
});

export const RefreshContext = React.createContext<RefreshContextType>({
    registerRefreshKey: () => null,
    unregisterRefreshKey: () => null,
    refresh: () => null,
});

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const delay = (t: number) => {
    return new Promise(resolve => setTimeout(resolve, t));
};

export const formatAmount = (amount: number) => {
    return `€\u00A0${amount.toFixed(2)}`;
};

export const validSmsContents = (message: string): boolean => {
    if (!message) {
        return true;
    }

    //It must include <Link> and it cannot be followed by a real character
    if (!message.match("<Link>($|\\s)")) {
        return false;
    }

    const allTokens = message.match(/<[^>]*>/g);
    const unknownTokens = (allTokens || []).filter(x => !['<Amount>', '<DocumentId>', '<Link>'].includes(x));
    if (unknownTokens.length > 0) {
        return false;
    }

    return true;
};

export const checkSession = async (): Promise<StatusResponse> => {
    const response = await fetch(`${BACKEND_URL}/users/session`, {
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('Check session no 403');
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const validate2fa = async (username: string, password: string): Promise<{
    enabled: boolean,
    configured: boolean,
    image: string
}> => {
    const response = await fetch(`${BACKEND_URL}/users/session/check-2fa-status`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            username: username,
            password: password,
            expiresIn: 60 * 60 * 12 * 365,
            platform: 'Web',
            appName: "Portal",
            environmentId: 'N/A',
        }),
    });

    return await response.json() as { enabled: boolean, configured: boolean, image: string };
};
export const login = async (username: string, password: string, token: string): Promise<LoginResponse> => {
    const response = await fetch(`${BACKEND_URL}/users/session/${token === '' ? '' : 'twoFactor'}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            username: username,
            password: password,
            totpCode: token,
            expiresIn: 60 * 60 * 12 * 365,
            platform: 'Web',
            appName: "Portal",
            environmentId: 'N/A',
        }),
    });

    if (!response.ok) {
        if (response.status !== 401) {
            Sentry.captureMessage('Login no 201 or 401', {
                extra: {
                    username: username,
                },
            });
        }

        //Return something else if we need a more fine grained error reporting
        return Promise.reject();
    }

    const data = await response.json() as LoginResponse;

    if (!data.authorities.find(authority => authority.authority === 'ROLE_PORTAL')) {
        Sentry.captureMessage('Login ROLE_PORTAL missing', {
            extra: {
                username: username,
            },
        });

        //Return something else if we need a more fine grained error reporting
        return Promise.reject();
    }

    if (
        !data.extraSettings ||
        !data.extraSettings.companies
    ) {
        Sentry.captureMessage('Login extraSettings missing', {
            extra: {
                username: username,
            },
        });

        //Return something else if we need a more fine grained error reporting
        return Promise.reject();
    }

    return data;
};

export const logout = async (userId: number): Promise<void> => {
    //userId is only used for logging

    const response = await fetch(`${BACKEND_URL}/users/session`, {
        method: 'DELETE',
        credentials: 'include',
    });

    if (!response.ok) {
        Sentry.captureMessage('Logout failed', {
            extra: {
                userId: userId,
            },
        });

        return Promise.reject();
    }
};

export const fetchTransactions = async (searchParams: string, companyId: number): Promise<GenericTableResponse<TransactionTableType>> => {
    const response = await fetch(`${BACKEND_URL}/portal/transactions?${searchParams}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch transactions failed', {
                extra: {
                    searchParams: searchParams,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const startTransactionsDownload = async (searchParams: string, companyId: number): Promise<DownloadResponse> => {
    const response = await fetch(`${BACKEND_URL}/portal/transactionsExport?${searchParams}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('start transactions download failed', {
                extra: {
                    searchParams: searchParams,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchExports = async (searchParams: string, companyId: number): Promise<GenericTableResponse<ExportTableType>> => {
    const parsedSearchParams = queryString.parse(searchParams);
    parsedSearchParams['billingSender.id'] = String(companyId);
    searchParams = queryString.stringify(parsedSearchParams);

    const response = await fetch(`${BACKEND_URL}/export/exports?${searchParams}`, {
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch exports failed', {
                extra: {
                    searchParams: searchParams,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const exportStatus = async (exportId: number): Promise<ExportStatusResponse> => {
    const response = await fetch(`${BACKEND_URL}/export/status?id=${exportId}`, {
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('export status failed', {
                extra: {
                    exportId: exportId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const saveExport = async (exportId: number, reference: string, mailWhenComplete: boolean): Promise<ExportStatusResponse> => {
    const response = await fetch(`${BACKEND_URL}/export/save?id=${exportId}&reference=${reference}&mailWhenComplete=${mailWhenComplete}`, {
        method: 'POST',
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('export save failed', {
                extra: {
                    exportId: exportId,
                    reference: reference,
                    mailWhenComplete: mailWhenComplete,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const cancelExport = async (exportId: number): Promise<ExportStatusResponse> => {
    const response = await fetch(`${BACKEND_URL}/export/cancel?id=${exportId}`, {
        method: 'POST',
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('export cancel failed', {
                extra: {
                    exportId: exportId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const exportDownloadUrl = (exportId: number) => {
    return `${BACKEND_URL}/export/download?id=${exportId}`;
};

export const fetchDocuments = async (searchParams: string, companyId: number): Promise<GenericTableResponse<DocumentTableType>> => {
    const response = await fetch(`${BACKEND_URL}/portal/documents?${searchParams}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch transactions failed', {
                extra: {
                    searchParams: searchParams,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchDocument = async (documentId: string): Promise<DocumentType> => {
    const response = await fetch(`${BACKEND_URL}/portal/document/${documentId}`, {
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch document failed', {
                extra: {
                    documentId: documentId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchTransaction = async (transactionId: string): Promise<TransactionType> => {
    const response = await fetch(`${BACKEND_URL}/portal/transaction/${transactionId}`, {
        credentials: 'include',
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch transaction failed', {
                extra: {
                    transactionId: transactionId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const changePassword = async (userId: number, oldPassword: string, newPassword: string): Promise<void> => {
    //userId is only used for logging

    const data = new FormData();
    data.append('oldPassword', oldPassword);
    data.append('newPassword', newPassword);

    const response = await fetch(`${BACKEND_URL}/portal/passwordUpdate`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status === 400) {
            const {message} = await response.json();
            return Promise.reject(message);
        } else {
            if (response.status !== 403) {
                Sentry.captureMessage('change password failed', {
                    extra: {
                        userId: userId,
                    },
                });
            }
            return Promise.reject(response.status);
        }
    }
};

export const isTableHeaderNormalType = <T>(header: TableHeaderType<T>): header is TableHeaderNormalType<T> => {
    return !header.isHelperColumn;
};

export const fetchAllowedValues = async (type: string, companyId: number): Promise<AllowedValuesEntry> => {
    const response = await fetch(`${BACKEND_URL}/portal/allowedValues?type=${type}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch allowedValues failed', {
                extra: {
                    type: type,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchStatsSuccessfulTransactions = async (groupBy: string, fromDate: string, toDate: string, companyId: number): Promise<StatsSuccessfulTransactionsResponse> => {
    const response = await fetch(`${BACKEND_URL}/portal/statsSuccessfulTransactions?groupBy=${groupBy}&fromDate=${fromDate}&toDate=${toDate}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch StatsSuccessfulTransactions failed', {
                extra: {
                    groupBy: groupBy,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchStatsPaymentSpeed = async (groupBy: string, date: string, companyId: number): Promise<StatsPaymentSpeedResponse> => {
    const response = await fetch(`${BACKEND_URL}/portal/statsPaymentSpeed?groupBy=${groupBy}&date=${date}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch StatsPaymentSpeed failed', {
                extra: {
                    groupBy: groupBy,
                    date: date,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchHome = async (successfulTransactionsGroupBy: string, successfulTransactionsFromDate: string, successfulTransactionsToDate: string, paymentSpeedGroupBy: string, paymentSpeedDate: string, companyId: number): Promise<HomeResponse> => {
    const response = await fetch(`${BACKEND_URL}/portal/home?successfulTransactionsGroupBy=${successfulTransactionsGroupBy}&successfulTransactionsFromDate=${successfulTransactionsFromDate}&successfulTransactionsToDate=${successfulTransactionsToDate}&paymentSpeedGroupBy=${paymentSpeedGroupBy}&paymentSpeedDate=${paymentSpeedDate}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch home failed', {
                extra: {
                    successfulTransactionsGroupBy: successfulTransactionsGroupBy,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const uploadSmsFile = async ({
                                        file,
                                        reference,
                                        mailWhenComplete,
                                        companyId,
                                        sender,
                                        documentType,
                                        dueDate,
                                        expiryDate,
                                        messageNL,
                                        messageFR,
                                        messageEN,
                                        documentIdNbChars,
                                        amountNbChars,
                                    }:
                                        {
                                            file: File;
                                            reference: string;
                                            mailWhenComplete: boolean;
                                            companyId: number;
                                            sender: string;
                                            documentType: string;
                                            dueDate: string;
                                            expiryDate: string;
                                            messageNL: string;
                                            messageFR: string;
                                            messageEN: string;
                                            documentIdNbChars: number;
                                            amountNbChars: number;
                                        }): Promise<UploadFile> => {
    const formData = new FormData();
    formData.append('reference', reference);
    formData.append('mailWhenComplete', String(mailWhenComplete));
    formData.append('file', file);

    formData.append('sender', sender);
    formData.append('documentType', documentType);
    formData.append('dueDate', dueDate);
    formData.append('expiryDate', expiryDate);
    formData.append('messageNL', messageNL);
    formData.append('messageFR', messageFR);
    formData.append('messageEN', messageEN);
    formData.append('documentIdNbChars', String(documentIdNbChars));
    formData.append('amountNbChars', String(amountNbChars));

    const response = await fetch(`${BACKEND_URL}/portal/uploadSmsXlsx`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'X-Company-Id': String(companyId),
        },
        body: formData,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('upload smsFile failed', {
                extra: {
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchSmsFiles = async (searchParams: string, companyId: number): Promise<GenericTableResponse<SmsFileTableType>> => {
    const response = await fetch(`${BACKEND_URL}/portal/smsFiles?${searchParams}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch smsFiles failed', {
                extra: {
                    searchParams: searchParams,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchSmsFile = async (smsFileId: string, companyId: number): Promise<SmsFileType> => {
    const response = await fetch(`${BACKEND_URL}/portal/smsFile/${smsFileId}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch smsFile failed', {
                extra: {
                    smsFileId: smsFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const saveSmsFile = async ({
                                      smsFileId,
                                      reference,
                                      documentType,
                                      dueDate,
                                      expiryDate,
                                      messageNL,
                                      messageFR,
                                      messageEN,
                                      documentIdNbChars,
                                      amountNbChars,
                                      file,
                                  }: {
    smsFileId: number;
    reference: string;
    documentType: string
    dueDate: string;
    expiryDate: string;
    messageNL: string;
    messageFR: string;
    messageEN: string;
    documentIdNbChars: number;
    amountNbChars: number;
    file?: File;
}): Promise<void> => {
    const data = new FormData();
    data.append('id', String(smsFileId));
    data.append('reference', reference);
    data.append('documentType', documentType);
    data.append('dueDate', dueDate);
    data.append('expiryDate', expiryDate);
    data.append('messageNL', messageNL);
    data.append('messageFR', messageFR);
    data.append('messageEN', messageEN);
    data.append('documentIdNbChars', String(documentIdNbChars));
    data.append('amountNbChars', String(amountNbChars));

    if (file) {
        data.append('file', file);
    }

    const response = await fetch(`${BACKEND_URL}/portal/saveSmsFile`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('save smsFile failed', {
                extra: {
                    smsFileId: smsFileId,
                    reference: reference,
                },
            });
        }

        return Promise.reject(response.status);
    }
};

export const saveSmsFileReference = async (smsFileId: number, reference: string): Promise<void> => {
    const data = new FormData();
    data.append('id', String(smsFileId));
    data.append('reference', reference);

    const response = await fetch(`${BACKEND_URL}/portal/saveSmsFileReference`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('save smsFile reference failed', {
                extra: {
                    smsFileId: smsFileId,
                    reference: reference,
                },
            });
        }

        return Promise.reject(response.status);
    }
};

export const sendFileToBrowser = (filename: string, blob: any) => {
    let element: HTMLAnchorElement = document.getElementById('pom-browser-download-file-link') as HTMLAnchorElement;

    if (!element) {
        element = document.createElement('a');
        element.style.display = 'none';
        element.id = 'pom-browser-download-file-link';
        document.body.appendChild(element);
    }

    element.href = window.URL.createObjectURL(blob);
    element.download = filename;
    element.click();
};

export const retrieveFilenameFromHeaders = (headers: Headers, defaultValue: string) => {
    const header = headers.get('content-disposition');

    if (header) {
        const headerMatch = header.match(/filename=['"](.*)['"]/);
        if (headerMatch) {
            return headerMatch[1];
        }
    }

    return defaultValue;
};

export const downloadOriginalSmsFile = async (smsFileId: number, companyId: number): Promise<Response> => {
    const response = await fetch(`${BACKEND_URL}/portal/downloadSmsFileUploadedXlsx/${smsFileId}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('download original smsFile failed', {
                extra: {
                    smsFileId: smsFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return response;
};

export const downloadProcessedSmsFile = async (smsFileId: number, companyId: number): Promise<Response> => {
    const response = await fetch(`${BACKEND_URL}/portal/downloadSmsFileProcessedXlsx/${smsFileId}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('download processed smsFile failed', {
                extra: {
                    smsFileId: smsFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return response;
};

export const processSmsFile = async (smsFileId: number, mailWhenComplete: boolean): Promise<SmsFileType> => {
    const data = new FormData();
    data.append('id', String(smsFileId));
    data.append('mailWhenComplete', String(mailWhenComplete));

    const response = await fetch(`${BACKEND_URL}/portal/processSmsFile`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('process smsFile failed', {
                extra: {
                    smsFileId: smsFileId,
                },
            });
        }

        if (String(response.status)[0] === "4") {
            const data = await response.json();
            return Promise.reject(data);
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const cancelSmsFile = async (smsFileId: number): Promise<SmsFileType> => {
    const data = new FormData();
    data.append('id', String(smsFileId));

    const response = await fetch(`${BACKEND_URL}/portal/cancelSmsFile`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('cancel smsFile failed', {
                extra: {
                    smsFileId: smsFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

const downloadTemplateSmsFile = async (companyId: number): Promise<Response> => {
    const response = await fetch(`${BACKEND_URL}/portal/downloadSmsFileTemplateXlsx`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('download template smsFile failed', {
                extra: {},
            });
        }

        return Promise.reject(response.status);
    }

    return response;
};

export const handleDownloadTemplateSmsFile = async (currentCompanyId: number) => {
    const response = await downloadTemplateSmsFile(currentCompanyId);

    //fallback filename if no filename from server is received
    const filename = retrieveFilenameFromHeaders(response.headers, 'template.xlsx');

    const blob = await response.blob();
    sendFileToBrowser(filename, blob);
};

export const uploadPaylinksFile = async ({
                                             file,
                                             reference,
                                             mailWhenComplete,
                                             companyId,
                                             sender,
                                             documentType,
                                             dueDate,
                                             expiryDate,
                                             channel,
                                         }:
                                             {
                                                 file: File;
                                                 reference: string;
                                                 mailWhenComplete: boolean;
                                                 companyId: number;
                                                 sender: string;
                                                 documentType: string;
                                                 dueDate: string;
                                                 expiryDate: string;
                                                 channel: string;
                                             }): Promise<UploadFile> => {
    const formData = new FormData();
    formData.append('reference', reference);
    formData.append('mailWhenComplete', String(mailWhenComplete));
    formData.append('file', file);

    formData.append('sender', sender);
    formData.append('documentType', documentType);
    formData.append('dueDate', dueDate);
    formData.append('expiryDate', expiryDate);
    formData.append('channel', channel);

    const response = await fetch(`${BACKEND_URL}/portal/uploadPaylinksXlsx`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'X-Company-Id': String(companyId),
        },
        body: formData,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('upload paylinksFile failed', {
                extra: {
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchPaylinksFiles = async (searchParams: string, companyId: number): Promise<GenericTableResponse<PaylinksFileTableType>> => {
    const response = await fetch(`${BACKEND_URL}/portal/paylinksFiles?${searchParams}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch paylinkFiles failed', {
                extra: {
                    searchParams: searchParams,
                    companyId: companyId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const fetchPaylinksFile = async (paylinksFileId: string, companyId: number): Promise<PaylinksFileType> => {
    const response = await fetch(`${BACKEND_URL}/portal/paylinksFile/${paylinksFileId}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('fetch paylinksFile failed', {
                extra: {
                    smsFileId: paylinksFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const savePaylinksFile = async ({
                                           paylinksFileId,
                                           reference,
                                           documentType,
                                           dueDate,
                                           expiryDate,
                                           channel,
                                           file,
                                       }: {
    paylinksFileId: number;
    reference: string;
    documentType: string
    dueDate: string;
    expiryDate: string;
    channel: string;
    file?: File;
}): Promise<void> => {
    const data = new FormData();
    data.append('id', String(paylinksFileId));
    data.append('reference', reference);
    data.append('documentType', documentType);
    data.append('dueDate', dueDate);
    data.append('expiryDate', expiryDate);
    data.append('channel', channel);

    if (file) {
        data.append('file', file);
    }

    const response = await fetch(`${BACKEND_URL}/portal/savePaylinksFile`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('save paylinksFile failed', {
                extra: {
                    paylinksFileId: paylinksFileId,
                    reference: reference,
                },
            });
        }

        return Promise.reject(response.status);
    }
};

export const savePaylinksFileReference = async (paylinksFileId: number, reference: string): Promise<void> => {
    const data = new FormData();
    data.append('id', String(paylinksFileId));
    data.append('reference', reference);

    const response = await fetch(`${BACKEND_URL}/portal/savePaylinksFileReference`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('save paylinksFile reference failed', {
                extra: {
                    paylinksFileId: paylinksFileId,
                    reference: reference,
                },
            });
        }

        return Promise.reject(response.status);
    }
};

export const downloadOriginalPaylinksFile = async (paylinksFileId: number, companyId: number): Promise<Response> => {
    const response = await fetch(`${BACKEND_URL}/portal/downloadPaylinksFileUploadedXlsx/${paylinksFileId}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('download original paylinksFile failed', {
                extra: {
                    paylinksFileId: paylinksFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return response;
};

export const downloadProcessedPaylinksFile = async (paylinksFileId: number, companyId: number): Promise<Response> => {
    const response = await fetch(`${BACKEND_URL}/portal/downloadPaylinksFileProcessedXlsx/${paylinksFileId}`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('download processed paylinksFile failed', {
                extra: {
                    paylinksFileId: paylinksFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return response;
};

export const processPaylinksFile = async (paylinksFileId: number, mailWhenComplete: boolean): Promise<PaylinksFileType> => {
    const data = new FormData();
    data.append('id', String(paylinksFileId));
    data.append('mailWhenComplete', String(mailWhenComplete));

    const response = await fetch(`${BACKEND_URL}/portal/processPaylinksFile`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('process paylinksFile failed', {
                extra: {
                    paylinksFileId: paylinksFileId,
                },
            });
        }

        if (String(response.status)[0] === "4") {
            const data = await response.json();
            return Promise.reject(data);
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

export const cancelPaylinksFile = async (paylinksFileId: number): Promise<PaylinksFileType> => {
    const data = new FormData();
    data.append('id', String(paylinksFileId));

    const response = await fetch(`${BACKEND_URL}/portal/cancelPaylinksFile`, {
        method: 'POST',
        credentials: 'include',
        body: data,
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('cancel paylinksFile failed', {
                extra: {
                    paylinksFileId: paylinksFileId,
                },
            });
        }

        return Promise.reject(response.status);
    }

    return await response.json();
};

const downloadTemplatePaylinksFile = async (companyId: number): Promise<Response> => {
    const response = await fetch(`${BACKEND_URL}/portal/downloadPaylinksFileTemplateXlsx`, {
        credentials: 'include',
        headers: {
            'X-Company-Id': String(companyId),
        },
    });

    if (!response.ok) {
        if (response.status !== 403) {
            Sentry.captureMessage('download template paylinksFile failed', {
                extra: {},
            });
        }

        return Promise.reject(response.status);
    }

    return response;
};

export const handleDownloadTemplatePaylinksFile = async (currentCompanyId: number) => {
    const response = await downloadTemplatePaylinksFile(currentCompanyId);

    //fallback filename if no filename from server is received
    const filename = retrieveFilenameFromHeaders(response.headers, 'template.xlsx');

    const blob = await response.blob();
    sendFileToBrowser(filename, blob);
};

export const retrieveNewQrCode = async () => {

    const response = await fetch(`${BACKEND_URL}/users/2fa/generate-qr-code`, {
        method: 'POST',
        credentials: 'include',
    });

    if (!response.ok) {
        return Promise.reject(response.status);
    }

    return await response.json();
};

export const twoFactorSettingsUpdate = async (enabled: boolean, token: string) => {

    const response = await fetch(`${BACKEND_URL}/users/2fa`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            twoFaEnabled: enabled,
            twoFaConfigured: enabled,
            token: token
        })
    });

    if (!response.ok) {
        return Promise.reject(await response.json());
    }

    return await response.json();
};
